<template>
  <v-dialog
    v-bind="$attrs"
    v-model="dialog"
    :transition="isModal ? 'none' : $attrs.transition"
    :attach="isModal ? '#custom_modal__portal' : false"
  >
    <div
      ref="content"
      :class="{ slideIn: isModal, custom_modal__card_container: true }"
    >
      <s-card
        class="pa-5 pa-lg-0 scroll-y"
      >
        <div class="px-9 pt-6 d-flex">
          <s-text
            class="flex"
            color="black"
            weight="bold"
            size="lg"
          >
            {{ title }}
          </s-text>
          <v-btn
            depressed
            icon
            class="back-btn"
            @click="closeModal"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <slot />
      </s-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'SDialog',
  props: {
    value: {},
    isModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    closeDefault: {
      type: Boolean,
      default: true,
    },
  },
  inheritAttrs: false,
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    animateDialogContent () {
      if (this.dialog && this.isModal) {
        this.$refs.content.classList.add('slideIn')
        this.$refs.content.classList.remove('slideOut')
      } else {
        this.$refs.content.classList.add('slideOut')
        this.$refs.content.classList.remove('slideIn')
      }
    },
    closeModal () {
      if (this.closeDefault) {
        this.$router.go(-1)
      }

      this.$emit('close')
    },
  },
  watch: {
    value: function () {
      if (!this.$refs.content) return

      this.animateDialogContent()
    },
  },
}
</script>
